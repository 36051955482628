import React, {Component} from 'react';
import PropTypes from 'prop-types';

// Components
import {
  Button,
  Container,
  Row,
  Column,
  Table,
  Select,
  Tag,
} from '@matthahn/sally-ui';
import AttributeFormatter from '../../../layout/components/AttributeFormatter/AttributeFormatter';
import VehicleTags from '../VehicleTags/VehicleTags';

import PageContainer from './components/Container';
import Content from './components/Content';
import StateSelect from './components/StateSelect';

// Attributes
import vin from '../../attributes/vin.attribute.vehicle';
import vehicle_year from '../../attributes/vehicle_year.attribute.vehicle';
import vehicle_model from '../../attributes/vehicle_model.attribute.vehicle';
import state from '../../attributes/state.attribute.vehicle';
import plate from '../../attributes/plate.attribute.vehicle';

// State
import vehicleState from '../../state';

class VehiclesList extends Component {
  static propTypes = {
    hasPermission: PropTypes.bool,
    results: PropTypes.number,
    tab: PropTypes.array,
    tabs: PropTypes.array,
    onTabChange: PropTypes.func,
    loading: PropTypes.bool,
    vehicles: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    onPage: PropTypes.func,
    sort: PropTypes.object,
    onSort: PropTypes.func,
    onVehicle: PropTypes.func,
    onNewVehicle: PropTypes.func,
  };

  columns = () =>
    [
      {
        key: 'vin',
        label: 'VIN',
        sortable: true,
      },
      {
        key: 'medallion',
        label: 'Medallion',
      },
      {
        key: 'vehicle_year_model',
        label: 'Year and Model',
      },
      {
        key: 'plate',
        label: plate.label.short,
        sortable: true,
      },
      {
        key: 'state',
        label: state.label.short,
        sortable: true,
      },
    ].filter((col) => !!col);

  headerItems = (Content) =>
    [
      this.props.hasPermission && (
        <Content key="newvehicle">
          <Button
            icon="plus"
            onClick={this.props.onNewVehicle}
            size="small"
            theme="orange"
          >
            New Vehicle
          </Button>
        </Content>
      ),
      <Content key="statechange">
        <StateSelect>
          <Select
            value={this.props.tab}
            options={this.props.tabs}
            onChange={this.props.onTabChange}
            notFilterable
          />
        </StateSelect>
      </Content>,
    ].filter((dom) => !!dom);

  results = () =>
    `${this.props.results} vehicle${this.props.results === 1 ? '' : 's'}`;

  render() {
    const {
      loading,
      vehicles,
      page,
      pages,
      onPage,
      sort,
      onSort,
      onVehicle,
    } = this.props;
    return (
      <PageContainer>
        <Content>
          <Container>
            <Row margin>
              <Column>
                <Table
                  columns={this.columns()}
                  subtitle={this.results()}
                  infinite={false}
                  page={page}
                  pages={pages}
                  onPage={onPage}
                  loading={loading}
                  sort={sort}
                  onSort={onSort}
                  headerItems={this.headerItems}
                  theme="orange"
                >
                  {(TableRow) =>
                    vehicles.map((vehicle) => (
                      <TableRow onClick={onVehicle(vehicle.id)}>
                        {(TableCol) =>
                          [
                            <TableCol key="vin" uppercase bold>
                              <VehicleTags vehicle={vehicle} display="vin">
                                <AttributeFormatter
                                  attribute={vin(vehicle.vin)}
                                />
                              </VehicleTags>
                            </TableCol>,
                            <TableCol key="medallion">
                              {!!vehicle.medallion &&
                              !!vehicle.medallion.medallion_number
                                ? vehicle.medallion.medallion_number
                                : '-'}
                            </TableCol>,
                            <TableCol key="year-model">
                              <AttributeFormatter
                                attribute={vehicle_year(vehicle.vehicle_year)}
                              />{' '}
                              <AttributeFormatter
                                attribute={vehicle_model(vehicle.vehicle_model)}
                              />
                            </TableCol>,
                            <TableCol key="plate">
                              <AttributeFormatter
                                attribute={plate(vehicle.plate)}
                              />
                            </TableCol>,
                            <TableCol key="state">
                              <Tag theme={vehicleState(vehicle.state).color}>
                                {vehicleState(vehicle.state).label}
                              </Tag>
                            </TableCol>,
                          ].filter((col) => !!col)
                        }
                      </TableRow>
                    ))
                  }
                </Table>
              </Column>
            </Row>
          </Container>
        </Content>
      </PageContainer>
    );
  }
}

export default VehiclesList;
