import {date, fileName, folder} from '../../../document';

// Attributes
import plateAttr from '../../attributes/plate.attribute.vehicle';
import expirationAttr from '../../attributes/emissions_expiry.attribute.vehicle';
import issuedAttr from '../../attributes/emissions_issued.attribute.vehicle';

// Errors
import invalidEmissionsExpiryErr from '../../errors/invalidEmissionsExpiry.error.vehicle';
import plateMissmatchErr from '../../errors/plateMissmatch.error.vehicle';

// Libs
import isLastDayAfterTwelveMonths from '../../libs/isLastDayAfterTwelveMonths.lib.vehicle';

export default folder({
  attributes: [plateAttr, issuedAttr, expirationAttr],
  fileAttributes: ['plate'],
  defaultFileName: 'State Inspection',
  // STATE INSPECTION - VIN - UPLOAD DATE
  fileName: fileName(
    ({vehicle}) => `STATE INSPECTION - ${vehicle.vin} - ${date()}`
  ),
  folderName: 'State Inspections',
  preSaveValidation: ({vehicle, plate, emissions_expiry, emissions_issued}) => {
    if (vehicle.plate !== plate.api.format())
      throw plateMissmatchErr({message: 'Invalid Plate'});
    if (
      !isLastDayAfterTwelveMonths(
        emissions_issued.api.format(),
        emissions_expiry.api.format()
      )
    )
      throw invalidEmissionsExpiryErr({
        message: 'DO YOU KNOW HOW TO ADD?',
      });
  },
  type: 'stateInspection',
});
