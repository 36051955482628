import React from 'react';
import PropTypes from 'prop-types';

// Components
import {Card, Form, Row, Column} from '@matthahn/sally-ui';
import AttributeInput from '../../../layout/components/AttributeInput/AttributeInput';

const VehicleInsuranceCard = ({
  loading,
  adminEditableAttributes,
  onSave,
  onChange,
  errors,

  insurance_liability_number,
}) => (
  <Card title="Insurance" icon="contacts">
    {(Content) => (
      <Form onSubmit={onSave} enter>
        <Content>
          <Row>
            <Column>
              <AttributeInput
                value={insurance_liability_number}
                onChange={onChange}
                errors={errors}
                disabled={loading}
                auth={adminEditableAttributes}
              >
                {insurance_liability_number.label.default}
              </AttributeInput>
            </Column>
          </Row>
        </Content>
      </Form>
    )}
  </Card>
);

VehicleInsuranceCard.propTypes = {
  loading: PropTypes.bool,
  adminEditableAttributes: PropTypes.array,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  errors: PropTypes.array,

  insurance_liability_number: PropTypes.object,
};

export default VehicleInsuranceCard;
