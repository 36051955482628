import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// Actions
import {signOut as signOutAct} from '../../../auth/redux/actions';
import {show as showSpotlightAct} from '../../../spotlight/redux/actions';

// Components
import Navigation from '../../components/Navigation/Navigation';

// Libs
import isProduction from '../../../app/lib/isProduction.lib.app';

// Accidents
import accidentsIcon from '../../../accident/pages/AccidentsPage/icon';
import accidentsLabel from '../../../accident/pages/AccidentsPage/label';
import accidentsPage from '../../../accident/pages/AccidentsPage/page';
import accidentsRoute from '../../../accident/pages/AccidentsPage/route';

// Accident Pages
import accidentDocumentsPage from '../../../accident/pages/AccidentDocumentsPage/page';
import accidentInfoPage from '../../../accident/pages/AccidentInfoPage/page';
import accidentNotesPage from '../../../accident/pages/AccidentNotesPage/page';
import accidentPage from '../../../accident/pages/AccidentPage/page';

// Cyclops
import cyclopsIcon from '../../../cyclops/pages/CyclopsPage/icon';
import cyclopsLabel from '../../../cyclops/pages/CyclopsPage/label';
import cyclopsPage from '../../../cyclops/pages/CyclopsPage/page';
import cyclopsRoute from '../../../cyclops/pages/CyclopsPage/route';

// GeneralCheque
import chequeIcon from '../../../cheque/pages/GeneralChequePage/icon';
import chequeLabel from '../../../cheque/pages/GeneralChequePage/label';
import chequePage from '../../../cheque/pages/GeneralChequePage/page';
import chequeRoute from '../../../cheque/pages/GeneralChequePage/route';

// Dispatcher
import dispatcherIcon from '../../../dispatch/pages/DispatcherPage/icon';
import dispatcherLabel from '../../../dispatch/pages/DispatcherPage/label';
import dispatcherPage from '../../../dispatch/pages/DispatcherPage/page';
import dispatcherRoute from '../../../dispatch/pages/DispatcherPage/route';

// Drivers
import driversIcon from '../../../driver/pages/DriversPage/icon';
import driversLabel from '../../../driver/pages/DriversPage/label';
import driversPage from '../../../driver/pages/DriversPage/page';
import driversRoute from '../../../driver/pages/DriversPage/route';

// Medallions
import medallionsIcon from '../../../medallion/pages/MedallionsPage/icon';
import medallionsLabel from '../../../medallion/pages/MedallionsPage/label';
import medallionsPage from '../../../medallion/pages/MedallionsPage/page';
import medallionsRoute from '../../../medallion/pages/MedallionsPage/route';

// medallion permissions
import medallionAccessPermission from '../../../medallion/permissions/access.permission.medallion';

// MedallionOwners
import medallionOwnersIcon from '../../../medallionOwner/pages/MedallionOwnersPage/icon';
import medallionOwnersLabel from '../../../medallionOwner/pages/MedallionOwnersPage/label';
import medallionOwnersPage from '../../../medallionOwner/pages/MedallionOwnersPage/page';
import medallionOwnersRoute from '../../../medallionOwner/pages/MedallionOwnersPage/route';

// medallionOwner permissions
import medallionOwnerAccessPermission from '../../../medallionOwner/permissions/access.permission.medallionOwner';

// permission events
import showPermissionsEvent from '../../../permission/events/show.event.permission';

// permission permissions
import canUpdatePermissionsPermission from '../../../permission/permissions/canUpdatePermissions.permission.permission';

// Tolls
import tollsIcon from '../../../toll/pages/TollsPage/icon';
import tollsLabel from '../../../toll/pages/TollsPage/label';
import tollsPage from '../../../toll/pages/TollsPage/page';
import tollsRoute from '../../../toll/pages/TollsPage/route';

// Tickets
import ticketsIcon from '../../../ticket/pages/TicketsPage/icon';
import ticketsLabel from '../../../ticket/pages/TicketsPage/label';
import ticketsPage from '../../../ticket/pages/TicketsPage/page';
import ticketsRoute from '../../../ticket/pages/TicketsPage/route';

// Vehicles
import vehiclesIcon from '../../../vehicle/pages/VehiclesPage/icon';
import vehiclesLabel from '../../../vehicle/pages/VehiclesPage/label';
import vehiclesPage from '../../../vehicle/pages/VehiclesPage/page';
import vehiclesRoute from '../../../vehicle/pages/VehiclesPage/route';

// Libs
import {lib} from '@matthahn/sally-ui';

// Helpers
const {notify} = lib;

class NavigationContainer extends Component {
  static propTypes = {
    numberOfTolls: PropTypes.number,
    numberOfPendingAchs: PropTypes.number,
    numberOfPendingCheques: PropTypes.number,
    numberOfFailedPayoutAlerts: PropTypes.number,
    username: PropTypes.string,
    dispatch: PropTypes.func,
  };

  onAvatar = () => {};

  showPermissions = () => {
    if (!this.canUpdatePermissions()) return;
    showPermissionsEvent.publish();
  };

  canUpdatePermissions = () => canUpdatePermissionsPermission();

  onPage = (page) => () => {};

  signOut = (prompt = true) => {
    if (prompt)
      return notify({
        id: 'signOut',
        title: 'Confirm Sign Out',
        icon: undefined,
        content: 'Are you sure you want to sign out?',
        primary: {
          label: 'No',
          onClick: () => null,
        },
        secondary: {
          label: 'Yes',
          onClick: () => this.signOut(false),
        },
        closable: false,
        closeOnOutsideClick: true,
      });

    this.props.dispatch(signOutAct());
  };

  showSpotlight = () => this.props.dispatch(showSpotlightAct());

  actions = () => [
    this.canUpdatePermissions() && {
      icon: 'lock',
      onClick: this.showPermissions,
    },
    {
      icon: 'bells',
      onClick: () => {},
    },
    {
      icon: 'search1',
      onClick: this.showSpotlight,
    },
    {
      icon: 'logout',
      onClick: this.signOut,
    },
  ];

  pages = () => {
    return [
      {
        id: dispatcherPage,
        icon: dispatcherIcon,
        url: dispatcherRoute(),
        page: dispatcherLabel,
        selected: this.props.page === dispatcherPage,
      },
      {
        id: vehiclesPage,
        icon: vehiclesIcon,
        url: vehiclesRoute(),
        page: vehiclesLabel,
        selected: this.props.page === vehiclesPage,
      },
      medallionAccessPermission() && {
        id: medallionsPage,
        icon: medallionsIcon,
        url: medallionsRoute(),
        page: medallionsLabel,
        selected: this.props.page === medallionsPage,
      },
      medallionOwnerAccessPermission() && {
        id: medallionOwnersPage,
        icon: medallionOwnersIcon,
        url: medallionOwnersRoute(),
        page: medallionOwnersLabel,
        selected: this.props.page === medallionOwnersPage,
      },
      {
        id: driversPage,
        icon: driversIcon,
        url: driversRoute(),
        page: driversLabel,
        selected: this.props.page === driversPage,
      },
      {
        id: tollsPage,
        icon: tollsIcon,
        url: tollsRoute(),
        page: tollsLabel,
        selected: this.props.page === tollsPage,
        badge: this.props.numberOfTolls,
      },
      {
        id: ticketsPage,
        icon: ticketsIcon,
        url: ticketsRoute(),
        page: ticketsLabel,
        selected: this.props.page === ticketsPage,
        badge: this.props.numberOfTickets,
      },
      {
        id: chequePage,
        icon: chequeIcon,
        url: chequeRoute(),
        page: chequeLabel,
        selected: this.props.page === chequePage,
        badge:
          this.props.numberOfPendingCheques +
          this.props.numberOfPendingAchs +
          this.props.numberOfFailedPayoutAlerts,
      },
      {
        id: cyclopsPage,
        icon: cyclopsIcon,
        url: cyclopsRoute(),
        page: cyclopsLabel,
        selected: this.props.page === cyclopsPage,
      },
      {
        id: accidentsPage,
        icon: accidentsIcon,
        url: accidentsRoute(),
        page: accidentsLabel,
        selected: [
          accidentDocumentsPage,
          accidentInfoPage,
          accidentNotesPage,
          accidentPage,
          accidentsPage,
        ].includes(this.props.page),
      },
    ].filter((page) => !!page);
  };

  render() {
    const {username} = this.props;
    return (
      <Navigation
        logoUrl={dispatcherRoute()}
        actions={this.actions()}
        pages={this.pages()}
        avatar={username}
        live={isProduction()}
        onAvatarClick={this.onAvatar}
        onPage={this.onPage}
      />
    );
  }
}

export default connect((state) => ({
  username: state.auth.username,
  roles: state.auth.roles,
  page: state.layout.page,
  numberOfTickets: state.ticket.numberOfTickets,
  numberOfTolls: state.toll.numberOfTolls,
  numberOfPendingAchs: state.ach.pendingAchs?.length || 0,
  numberOfPendingCheques: state.cheque.pendingCheques?.length || 0,
  numberOfFailedPayoutAlerts: state.alert.failedPayoutAlerts?.length || 0,
}))(NavigationContainer);
