import {addMonths, lastDayOfMonth, startOfMonth, isSameDay} from 'date-fns';

// Lib
import toDate from '../../lib/toDate';

export default (issued, expiry) =>
  isSameDay(
    toDate(expiry),
    lastDayOfMonth(addMonths(startOfMonth(toDate(issued)), 12))
  );
