import {DATE} from '../../inputTypes';

// Attribute
import attribute from '../../attribute/attribute';

// Types
import {date, apiDate} from '../../types';

export default attribute({
  type: DATE,
  attribute: 'emissions_expiry',
  display: date,
  input: date,
  api: apiDate,
  label: {
    default: 'State Emissions EXPIRY',
    short:
      'EXPIRATION: When is the next state inspection due? Note, this is NOT necessarily the date on the sticker.',
  },
  disabledDates: [{before: new Date()}],
});
